@font-face {
  font-family: 'EarlyGameBoy';
  src: url('fonts/Early GameBoy.ttf') format('truetype');
}

* {
  box-sizing: border-box;
}

html, body, #root, .container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 15px;

}

body {
  background: white;
  color: black;
  font-family: 'EarlyGameBoy', serif;

}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

.hamburger-menu {
  display: none; /* Initially hidden */
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}

.hamburger-menu .hamburger-line {
  width: 30px;
  height: 3px;
  background-color: black;
  margin: 5px 0;
  transition: all 0.3s ease;
}

.hamburger-menu:hover .hamburger-line {
  background-color: #555;
}

.menu-items {
  position: absolute;
  top: 60px;
  left: 20px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.menu-items a {
  display: block;
  margin: 10px 0;
  color: black;
  text-decoration: none;
}

.overlay-text {
  position: absolute;
  font-family: 'EarlyGameBoy', serif;
  cursor: pointer;
}

.overlay-text.bottom-left {
  bottom: 40px;
  left: 70px;
}

.overlay-text.top-left {
  top: 40px;
  left: 40px;
  line-height: 1.6em;
  white-space: pre;
}

.overlay-text.bottom-right {
  bottom: 40px;
  right: 40px;
}

#cube_title{
  cursor: default;
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .hamburger-menu {
    display: block;
  }

  .overlay-text {
    display: none; /* Hide text on small screens */
  }

  #cube_title {
    display: block;
    position: absolute;
    pointer-events: all;
  }

  /* Responsive Text */
  .menu-items a {
    font-size: 13px; /* Adjust as needed for smaller screens */
  }
}

@media only screen and (max-width: 320px) {
  /* Further adjustments for very small screens */
  .menu-items a {
    font-size: 8px;
  }

  #cube_title {
    font-size: 8px;
  }
}

/* Modal Styles */
.modal-content, .modal-content-privacy, .modal-content-contact {
  position: fixed; /* Fixed position */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for exact centering */
  width: 80%;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid black; /* Black outline */
  max-width: 400px; /* Adjust max-width as needed for each modal */
  background: white; /* Set a background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow for better visibility */
  z-index: 1000; /* Ensure it's above other content */
}

.modal-content-privacy {
  max-width: 500px; /* Adjust max-width as needed for privacy modal */
}

.modal-content-contact {
  max-width: 650px; /* Adjust max-width as needed for contact modal */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 0.5s forwards;
}


.overlay-text-item {
  padding: 10px;
}

/* Overlay Text Hover Effect */
.overlay-text-item:hover {
  color: #fff; /* Change text color on hover */
  background-color: #000;
  border-radius: 5px;
  transform: scale(1.2); /* Scale up the text */
  transform-origin: center; /* Keep the scaling centered */
  transition: background-color 0.3s, transform 0.3s;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  padding: 0 10px; /* Add padding if needed */
}

.modal-close-button {
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  top: 25px;
  right: 20px;
  position: fixed;
  transition: all 0.3s ease;
}

/* Change SVG color on hover */
.modal-close-button:hover svg path {
  stroke: #fa320a; /* Change color on hover */
}


.project-list {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* Project Item Styles */
.project-item {
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.project-item:hover {
  background-color: black;
  transform: scale(1.1);
  transform-origin: center;
}

.project-item:hover .project-link {
  color: white; /* Changes the link color as soon as the item is hovered */
  text-decoration: none;
}

.project-link {
  color: black; /* Default text color */
  text-decoration: none;
  display: block; /* Make the entire area clickable */
  transition: color 0.3s; /* Smooth transition for color change */
}

.contact-content {
  text-align: center;
  margin-top: 20px;
}

.contact-email-link {
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin: 10px 0;
  word-break: break-all; /* Breaks the email address if it's too long for the line */
}

/* Responsive adjustments for smaller screens */
@media only screen and (max-width: 480px) {
  .modal-content-contact {
    width: 90%; /* Increase width for smaller screens */
    max-width: none;
    font-size: 15px/* Removes max-width constraint */
  }
  .contact-content {
    font-size: 13px
  }

  .modal-header {
    font-size: 20px
  }

  .contact-email-link {
    font-size: 13px;
    padding-bottom: 10px;
  }
}




