/* ColorBinderPrivacyPage.css */

@font-face {
    font-family: 'EarlyGameBoy';
    src: url('fonts/Early GameBoy.ttf') format('truetype');
}

.privacyPolicyContainer {
    font-family: 'Ubuntu', serif; /* Assuming you have this font loaded */
    font-size: 16px; /* Adjust as needed */
    color: #333; /* Adjust color as needed */
    line-height: 1.5;
    padding: 20px;
    /* Add more styles as per your requirement */
}
